import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
    DevicePlatform,
    FrontPageQueryQuery,
    FrontPageQueryQueryVariables,
    LocalPriceFragment,
    TicketLegFragment,
    TicketRowFragment,
} from "../generated/gql/graphql";
import { Link, useLocation } from "react-router-dom";
import { RelativeTimeText, TimestampText } from "../widgets/date_time";
import { formatMoney } from "./NetsTransactionsListPage";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Collapse,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Pagination,
    PaginationItem,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ScienceIcon from "@mui/icons-material/Science";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { usePageTitle } from "../util/page_title";
import { formatCustomerId, formatPercentage, formatTicketId } from "../util/formatting";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { formatBatteryUsage, formatLocationPermission } from "./ticket_v2/TicketAppTab";
import { DateTimeFormat, formatDuration } from "../util/date_time";
import { Masonry } from "@mui/lab";
import { isSuperUser } from "../util/active_user_context";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { pullAll, reverse, sortBy, sumBy, without } from "lodash";
import NavigationIcon from "@mui/icons-material/Navigation";
import { JsonDiagnosticButton } from "../widgets/diagnostics";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TicketLocalLegsTable } from "./ticket_v2/TicketInfoTab";
import ExpandIcon from "@mui/icons-material/Expand";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { LOCAL_PRICE_FRAGMENT } from "./TicketPageV2";
import { TICKET_LEGS_FRAGMENT, TicketLegsTable } from "./ticket/TicketLegsTable";
import WarningIcon from "@mui/icons-material/Warning";

export const TICKET_ROW_FRAGMENT = gql`
    fragment TicketRow on Ticket {
        id
        createdTime
        discount
        hasTelemetryData
        isSimulatedTicket
        refundApproved
        passengerTypeInfo {
            textDescription
            passengerType
            addonBicycle
            passengerTypeAddons
        }
        analysisConfidence

        endedReason
        endedTime

        startBatteryLevel
        endedBatteryLevel

        price
        basePrice
        invoicedTime
        processedTime
        amountDue
        amountPaid
        amountCaptured
        amountCredited

        statusPaymentText
        statusText
        verified
        legsDescription
        telemetryDataUploadConsentGiven
        telemetryDataUploadRequired
        automaticPurchase
        #  questionnaire { answered }

        customer {
            id
            phoneNumberFormatted
            name
            activeResearchGroup
        }

        deviceInfo {
            platform
            appVersionName
            appBuildCode
            androidManufacturer
            androidModel
            androidProduct
            androidSdkInt
            androidVersionRelease
            iosModel
            iosSystemVersion
            locationPermission
            pinchSurveysEnabled
            pinchAnalyticsEnabled
        }

        activeLocalPriceAnalysis {
            ...LocalPrice
        }
        legs {
            ...TicketLeg
        }
    }

    ${LOCAL_PRICE_FRAGMENT}
    ${TICKET_LEGS_FRAGMENT}
`;

const FRONT_PAGE_QUERY = gql`
    query FrontPageQuery($query: String!, $page: Int!, $limit: Int!) {
        recentTickets(query: $query, page: $page, limit: $limit) {
            totalPages
            result {
                ...TicketRow
            }
        }
    }

    ${TICKET_ROW_FRAGMENT}
`;

type Ticket = TicketRowFragment;

export function FrontPage() {
    usePageTitle("Reisefrihet portal");
    const basePath = "";

    const visibleColumnsItemKey = "frontpage.visibleColumns";
    const [visibleColumns, setVisibleColumns] = useState<Set<ColumnId>>(
        readVisibleColumnsFromLocalStorage(visibleColumnsItemKey, defaultVisibleColumns),
    );
    const location = useLocation();
    const locationQuery = new URLSearchParams(location.search);
    const page = parseInt(locationQuery.get("page") || "0", 10) || 1;

    const [searchQuery, setSearchQuery] = useState("");

    const query = useQuery<FrontPageQueryQuery, FrontPageQueryQueryVariables>(FRONT_PAGE_QUERY, {
        variables: {
            query: searchQuery,
            page: page - 1,
            limit: 40,
        },
    });
    const recentTickets = (query.data || query.previousData)?.recentTickets;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div style={{ width: "100%", maxWidth: visibleColumns.size > 2 ? "2500px" : "1300px", margin: "0 auto" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            visibility: query.loading ? "visible" : "hidden",
                        }}
                    >
                        <CircularProgress />
                    </div>

                    <div style={{ width: "400px", margin: "40px 20px" }}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={"Søk etter billettnummer, app-ID, eller mobilnummer"}
                            variant={"filled"}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <div>
                        <ColumnsButton
                            value={visibleColumns}
                            onChange={(newValue) => {
                                setVisibleColumns(newValue);
                                writeVisibleColumsToLocalStorage(visibleColumnsItemKey, newValue);
                            }}
                        />
                    </div>
                </div>

                <TicketsTable
                    showCustomerInfo={true}
                    tickets={((recentTickets?.result as unknown) || []) as ReadonlyArray<TicketRowFragment>}
                    visibleColumns={visibleColumns}
                />

                <div
                    style={{
                        margin: "0 auto",
                        width: "max-content",
                        marginTop: "30px",
                    }}
                >
                    <Pagination
                        count={recentTickets?.totalPages || 1}
                        page={page}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                // @ts-ignore
                                to={`${basePath}/?page=${item.page}`}
                                {...item}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

function FooterRow(props: {
    showCustomerInfo: boolean;
    tickets: ReadonlyArray<Ticket>;
    visibleColumns: ReadonlySet<ColumnId>;
}) {
    const visibleColumnsObjects = columns.filter((column) => props.visibleColumns.has(column.key));

    return (
        <TableRow>
            <TableCell colSpan={2 + (props.showCustomerInfo ? 1 : 0)}>Sum</TableCell>
            {visibleColumnsObjects.map((column) => {
                return (
                    <TableCell key={column.key} align={column.align}>
                        {column.footerBuilder ? (
                            <Typography variant={"body2"}>{column.footerBuilder(props.tickets)}</Typography>
                        ) : null}
                    </TableCell>
                );
            })}
            <TableCell colSpan={4} />
        </TableRow>
    );
}

function TicketsTable({
    showCustomerInfo,
    showFooter,
    tickets,
    visibleColumns,
}: {
    showCustomerInfo: boolean;
    showFooter?: boolean;
    tickets: ReadonlyArray<Ticket>;
    visibleColumns: ReadonlySet<ColumnId>;
}) {
    const eventTarget = useMemo(() => new EventTarget(), []);
    const [expandedTicketIds, setExpandedTicketIds] = useState<ReadonlyArray<number>>([]);
    const allExpanded =
        pullAll(
            tickets.map((t) => t.id),
            expandedTicketIds,
        ).length === 0;

    return (
        <TableContainer component={Paper} sx={{ maxHeight: "900px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <IconButton
                                onClick={() => {
                                    if (allExpanded) setExpandedTicketIds([]);
                                    else setExpandedTicketIds(tickets.map((t) => t.id));
                                }}
                            >
                                {allExpanded ? <UnfoldLessIcon /> : <ExpandIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell>Billett-Id</TableCell>
                        {showCustomerInfo ? (
                            <>
                                <TableCell>App-Id</TableCell>
                                <TableCell>Mobilnummer</TableCell>
                            </>
                        ) : null}
                        {columns
                            .filter((column) => visibleColumns.has(column.key))
                            .map((column) => {
                                return (
                                    <TableCell key={column.key} align={column.align}>
                                        <Typography variant={"caption"}> {column.category}</Typography>
                                        <br />
                                        {column.label}
                                    </TableCell>
                                );
                            })}
                        <TableCell />
                        {isSuperUser() ? <TableCell /> : null}
                    </TableRow>

                    {showFooter ? (
                        <FooterRow
                            showCustomerInfo={showCustomerInfo}
                            tickets={tickets}
                            visibleColumns={visibleColumns}
                        />
                    ) : null}
                </TableHead>

                <TableBody>
                    {tickets.map((t) => {
                        const ticket = t as TicketRowFragment;
                        return (
                            <TicketRow
                                key={ticket.id}
                                eventTarget={eventTarget}
                                expanded={expandedTicketIds.indexOf(ticket.id) !== -1}
                                setExpanded={(newValue) => {
                                    if (newValue) setExpandedTicketIds([ticket.id, ...expandedTicketIds]);
                                    else setExpandedTicketIds(without(expandedTicketIds, ticket.id));
                                }}
                                ticket={ticket as TicketRowFragment}
                                visibleColumns={visibleColumns}
                                showCustomerInfo={showCustomerInfo}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type TicketRowProps = {
    showCustomerInfo: boolean;
    expanded: boolean;
    setExpanded: (newValue: boolean) => void;
    eventTarget: EventTarget;
    ticket: TicketRowFragment;
    visibleColumns: ReadonlySet<ColumnId>;
};

function TicketRow({ showCustomerInfo, expanded, setExpanded, ticket, visibleColumns }: TicketRowProps) {
    const visibleColumnsObjects = columns.filter((column) => visibleColumns.has(column.key));
    const columnCount = 2 + (showCustomerInfo ? 1 : 0) + visibleColumnsObjects.length + 1 + (isSuperUser() ? 1 : 0);

    return [
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    disabled={!ticket.activeLocalPriceAnalysis}
                >
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell>
                <Link to={"/ticket2/" + ticket.id}>{formatTicketId(ticket.id)}</Link>
            </TableCell>
            {showCustomerInfo ? (
                <>
                    <TableCell>
                        <Link to={"/customer/" + ticket.customer.id}>{formatCustomerId(ticket.customer.id)}</Link>
                    </TableCell>
                    <TableCell>
                        {(ticket.customer.phoneNumberFormatted || "") +
                            (ticket.customer.name ? " (" + ticket.customer.name + ")" : "")}
                    </TableCell>
                </>
            ) : null}
            {visibleColumnsObjects.map((column) => {
                return (
                    <TableCell key={column.key} align={column.align}>
                        {column.cellBuilder ? column.cellBuilder(ticket) : (ticket[column.key as keyof Ticket] as any)}
                    </TableCell>
                );
            })}
            <TableCell>
                {buildFlagIconOrNull(
                    ticket.telemetryDataUploadRequired,
                    <KeyOutlinedIcon fontSize={"inherit"} />,
                    "Data-deling er påkrevd",
                ) ||
                    buildFlagIcon(
                        ticket.telemetryDataUploadConsentGiven,
                        <LockOpenOutlinedIcon fontSize={"inherit"} />,
                        "Har samtykket til data-deling",
                    )}
                {buildFlagIcon(ticket.hasTelemetryData, <GpsFixedIcon fontSize={"inherit"} />, "Har GPS-data")}
                {buildFlagIcon(
                    ticket.verified,
                    <VerifiedUserIcon fontSize={"inherit"} />,
                    "Pris/analyse har blitt manuelt godkjent",
                )}
                {buildFlagIcon(ticket.isSimulatedTicket, <ScienceIcon fontSize={"inherit"} />, "Simulert billett")}
                {buildFlagIcon(ticket.refundApproved, <CreditCardOffIcon fontSize={"inherit"} />, "Pris korrigert")}
                {buildFlagIcon(
                    ticket.deviceInfo?.pinchSurveysEnabled || ticket.deviceInfo?.pinchAnalyticsEnabled,
                    <QuestionAnswerIcon fontSize={"inherit"} />,
                    "Pinch aktivert",
                )}
                {buildFlagIcon(ticket.automaticPurchase, <NavigationIcon fontSize={"inherit"} />, "Automatisk kjøpt")}
                {/*{buildFlagIcon(ticket.questionnaire?.answered, <PollIcon fontSize="small />, "Spørreundersøkelse besvart")}*/}
            </TableCell>
            {isSuperUser() ? (
                <TableCell style={{ width: "40px" }}>
                    <JsonDiagnosticButton name={"ticket"} value={ticket} />
                </TableCell>
            ) : null}
        </TableRow>,
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnCount}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div"></Typography>
                        {ticket.activeLocalPriceAnalysis != null ? (
                            <TicketLocalLegsTable
                                ticket={ticket}
                                localPrice={ticket.activeLocalPriceAnalysis as LocalPriceFragment}
                            />
                        ) : null}
                        {ticket.legs.length > 0 ? (
                            <TicketLegsTable legs={ticket.legs as unknown as TicketLegFragment[]} />
                        ) : null}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>,
    ];
}

function buildFlagIconOrNull(visible: boolean, icon: React.JSX.Element, title: React.ReactNode) {
    if (!visible) return null;
    return buildFlagIcon(visible, icon, title);
}

export function buildFlagIcon(visible: boolean | null | undefined, icon: React.JSX.Element, title: React.ReactNode) {
    return (
        <Tooltip
            title={title}
            style={{
                visibility: visible ? "visible" : "hidden",
                fontSize: "20px",
            }}
        >
            {icon}
        </Tooltip>
    );
}

function formatDeviceModel(device: TicketRowFragment["deviceInfo"]): string {
    if (!device) return "";

    switch (device.platform) {
        case DevicePlatform.Android:
            return device.androidManufacturer + " – " + device.androidModel;
        case DevicePlatform.Ios:
            return device.iosModel || "";
        default:
            return "";
    }
}

function formatDeviceOSVersion(device: Ticket["deviceInfo"]): string {
    if (!device) return "";

    switch (device.platform) {
        case DevicePlatform.Android:
            return `Android ${device.androidVersionRelease} (SDK ${device.androidSdkInt})`;
        case DevicePlatform.Ios:
            return "iOS " + device.iosSystemVersion;
        default:
            return "";
    }
}

interface ColumnDefinition {
    key: string;
    label: string;
    align?: "left" | "right";
    category: (typeof ColumnCategories)[number];
    cellBuilder?: (row: Ticket) => React.ReactNode;
    footerBuilder?: (rows: ReadonlyArray<Ticket>) => React.ReactNode;
    defaultVisible?: boolean;
    requiredVisible?: boolean;
    requiresSuperUser?: boolean;
}

const ColumnCategories = ["Billett", "Kunde", "App", "Mobil", "Prosessering", "Korttrekk (kr)"];

function buildAmountDue(amountDue: number) {
    if (amountDue == 0) return null;
    if (amountDue > 0) return formatMoney(amountDue);
    else
        return (
            <span>
                <WarningIcon color={"error"} />
                {"Overbelastet "}
                <br />
                {formatMoney(-amountDue)}
            </span>
        );
}

const columns: ReadonlyArray<ColumnDefinition> = [
    {
        key: "createdTime",
        label: "Kjøpt",
        category: "Billett",
        cellBuilder: (ticket) => <TimestampText value={ticket.createdTime} />,
        defaultVisible: true,
    },
    {
        key: "passengerType",
        label: "Type",
        category: "Billett",
        cellBuilder: (ticket) => ticket.passengerTypeInfo.textDescription,
        defaultVisible: true,
    },
    {
        key: "legsDescription",
        label: "Reisebeskrivelse",
        category: "Billett",
    },
    {
        key: "endedReason",
        label: "Avsluttingsgrunn",
        category: "Billett",
    },
    {
        key: "endedTime",
        label: "Avsluttingstid",
        cellBuilder: (ticket) => <TimestampText format={DateTimeFormat.TIME_SHORT} value={ticket.endedTime} />,
        category: "Billett",
    },
    {
        key: "processedTime",
        label: "ticket.processedTime",
        cellBuilder: (ticket) => <RelativeTimeText value={ticket.processedTime} relativeTo={ticket.createdTime} />,
        category: "Prosessering",
    },
    {
        key: "invoicedTime",
        label: "ticket.invoicedTime",
        cellBuilder: (ticket) => <RelativeTimeText value={ticket.invoicedTime} relativeTo={ticket.createdTime} />,
        category: "Prosessering",
    },
    {
        key: "statusText",
        label: "Status",
        cellBuilder: (ticket) => ticket.statusText,
        category: "Billett",
        requiredVisible: true,
    },
    {
        key: "statusPaymentText",
        label: "Betalt",
        cellBuilder: (ticket) => ticket.statusPaymentText,
        category: "Billett",
        requiredVisible: true,
    },
    {
        key: "price",
        label: "Billettpris",
        cellBuilder: (ticket) => (ticket.invoicedTime ? formatMoney(ticket.price) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "price")),
        category: "Billett",
        align: "right",
        requiredVisible: true,
    },
    {
        key: "amountCaptured",
        label: "Trukket",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountCaptured !== 0 ? formatMoney(ticket.amountCaptured) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountCaptured")),
        category: "Korttrekk (kr)",
    },
    {
        key: "amountCredited",
        label: "Refundert",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountCredited !== 0 ? formatMoney(ticket.amountCredited) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountCredited")),
        category: "Korttrekk (kr)",
    },
    {
        key: "amountPaid",
        label: "Betalt",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountPaid !== 0 ? formatMoney(ticket.amountPaid) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountPaid")),
        category: "Korttrekk (kr)",
    },
    {
        key: "amountDue",
        label: "Utestående",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountDue !== 0 ? buildAmountDue(ticket.amountDue) : null),
        footerBuilder: (tickets) => buildAmountDue(sumBy(tickets, "amountDue")),
        defaultVisible: true,
        category: "Korttrekk (kr)",
    },
    {
        key: "duration",
        label: "Varighet",
        align: "right",
        cellBuilder: (ticket) => formatDuration(ticket.createdTime, ticket.endedTime),
        category: "Billett",
    },
    {
        key: "analysisConfidence",
        label: "Treffsikkerhet",
        align: "right",
        cellBuilder: (ticket) => (ticket.processedTime ? formatPercentage(ticket.analysisConfidence) : null),
        category: "Billett",
    },
    {
        key: "discount",
        label: "Rabatt",
        align: "right",
        cellBuilder: (ticket) => ticket.discount + " %",
        category: "Billett",
    },
    {
        key: "activeResearchGroup",
        label: "Gruppe",
        cellBuilder: (ticket) => ticket.customer.activeResearchGroup,
        category: "Kunde",
    },
    {
        key: "appVersionName",
        label: "Versjon",
        category: "App",
        cellBuilder: (ticket) => ticket.deviceInfo?.appVersionName + "+" + ticket.deviceInfo?.appBuildCode,
    },
    {
        key: "devicePlatform",
        label: "OS/Versjon",
        category: "Mobil",
        cellBuilder: (ticket) => formatDeviceOSVersion(ticket.deviceInfo),
    },
    {
        key: "deviceModel",
        label: "Modell",
        category: "Mobil",
        cellBuilder: (ticket) => formatDeviceModel(ticket.deviceInfo),
    },
    {
        key: "deviceProduct",
        label: "Produktkode",
        category: "Mobil",
        cellBuilder: (ticket) => ticket.deviceInfo?.androidProduct,
        requiresSuperUser: true,
    },
    {
        key: "deviceLocationPermission",
        label: "Steds-tillatelse",
        category: "App",
        cellBuilder: (ticket) => formatLocationPermission(ticket.deviceInfo?.locationPermission),
    },
    {
        key: "batteryUsage",
        label: "Batteri-forbruk",
        category: "App",
        align: "right",
        cellBuilder: (ticket) => formatBatteryUsage(ticket),
        requiresSuperUser: true,
    },
];

function isColumnAvailable(column: ColumnDefinition): boolean {
    if (column.requiresSuperUser) {
        return isSuperUser();
    } else {
        return true;
    }
}

type ColumnId = string;

function ColumnsButton(props: { value: ReadonlySet<ColumnId>; onChange: (newValue: Set<ColumnId>) => void }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (columnId: ColumnId) => {
        const newValue = new Set(props.value);
        if (!newValue.has(columnId)) newValue.add(columnId);
        else newValue.delete(columnId);

        props.onChange(newValue);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    size="medium"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    startIcon={<VisibilityIcon />}
                >
                    Kolonner
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem style={{ padding: "20px" }}>
                                    <Masonry
                                        // wrap={"wrap"}
                                        columns={2}
                                        spacing={2}
                                        // direction={"row"}
                                        // spacing={2}
                                    >
                                        {ColumnCategories.map((category) => {
                                            return (
                                                <React.Fragment key={category}>
                                                    <Grid item md={6}>
                                                        <Typography variant={"h6"} style={{ paddingBottom: "10px" }}>
                                                            {category}
                                                        </Typography>
                                                        {columns
                                                            .filter(isColumnAvailable)
                                                            .filter((c) => c.category == category)
                                                            .map((column) => {
                                                                const selected = props.value.has(column.key);
                                                                return (
                                                                    <MenuItem
                                                                        key={column.key}
                                                                        selected={selected}
                                                                        onClick={() =>
                                                                            handleMenuItemClick(column.key as ColumnId)
                                                                        }
                                                                        style={{ background: "none" }}
                                                                    >
                                                                        {selected ? (
                                                                            <CheckBoxIcon />
                                                                        ) : (
                                                                            <CheckBoxOutlineBlankIcon />
                                                                        )}
                                                                        {column.label}
                                                                        {column.requiresSuperUser ? (
                                                                            <DeveloperModeIcon />
                                                                        ) : null}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Masonry>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

const defaultVisibleColumns = columns.filter((c) => c.defaultVisible || c.requiredVisible).map((c) => c.key);
const requiredVisibleColumns = columns.filter((c) => c.requiredVisible).map((c) => c.key);

function readVisibleColumnsFromLocalStorage(
    visibleColumnsItemKey: string,
    defaultVisibleColumns: ReadonlyArray<string>,
): Set<ColumnId> {
    const result = localStorage.getItem(visibleColumnsItemKey);
    const defaultValue = new Set<ColumnId>([...defaultVisibleColumns, ...requiredVisibleColumns]);
    if (!result) return defaultValue;

    try {
        const list = JSON.parse(result);
        return new Set<ColumnId>([...list, ...requiredVisibleColumns]);
    } catch (e) {
        console.error("Failed reading visibleRegions from cache", e);
        return defaultValue;
    }
}

function writeVisibleColumsToLocalStorage(visibleColumnsItemKey: string, value: Set<ColumnId>) {
    localStorage.setItem(visibleColumnsItemKey, JSON.stringify([...value]));
}

export function CustomerTicketTable(props: { tickets: ReadonlyArray<TicketRowFragment> }) {
    const visibleColumnsItemKey = "customer_tickets.visibleColumns";

    const [visibleColumns, setVisibleColumns] = useState<Set<ColumnId>>(
        readVisibleColumnsFromLocalStorage(
            visibleColumnsItemKey,
            defaultVisibleColumns.filter((key) => key != "passengerType"),
        ),
    );

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <ColumnsButton
                    value={visibleColumns}
                    onChange={(newValue) => {
                        setVisibleColumns(newValue);
                        writeVisibleColumsToLocalStorage(visibleColumnsItemKey, newValue);
                    }}
                />
            </div>
            <TicketsTable
                showFooter={true}
                showCustomerInfo={false}
                tickets={reverse(sortBy(props.tickets, "createdTime"))}
                visibleColumns={visibleColumns}
            />
        </div>
    );
}
